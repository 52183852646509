import React, { useState, useEffect, ReactNode, PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import ZooHeader from 'components/header/zoo';
import ExpHeader from 'components/header/exp';
import NewExpHeader from 'components/header/exp-v2';
import CommercialHeader from 'components/header/commercial';
import { trackCurrentPageView, trackDelayedPageView } from 'utils/google-tag-manager';
import { buildClassName } from 'utils/build-class-name';
import LoadingSpinner from 'components/loading-spinner';
import { useFeaturesContext } from 'contexts/features';
import MobileBottomNav from 'components/header/zoo/mobile-bottom-nav';
import {
  LISTING_ROUTE,
  ROOT_ROUTE,
  RouteName,
} from 'components/dynamic-page/route-matchers';
import { useThemeContext, useUser } from 'contexts';
import dynamic from 'next/dynamic';
import styles from './style.module.scss';
import expInServiceTenant from 'tenants/expInServiceTenant';
import { ThemeNames } from 'types/themes';
import { isThemeExpCA, isThemeExpUS } from 'utils/tenant/which-tenant';

type LayoutProps = PropsWithChildren<{
  className?: string;
  renderHeader?: () => ReactNode;
  showChatWidget?: boolean;
  showMobileBottomNav?: boolean;
  routeName?: RouteName;
}>

const useRouterLoading = () => {
  const router = useRouter();
  const { setHasNavigatedFromInternalLink } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const routeChangeStartHandler = () => {
      setHasNavigatedFromInternalLink(true);
      setIsLoading(true);
    };
    const routeChangeCompleteHandler = () => setIsLoading(false);

    /**
     * Due to rapid reloading in dev routeChangeComplete may never be called
     * Prevent the loading spinner from staying on the screen forever
     */
    const routeChangeError = (error: any) => {
      if (error.message === 'Cancel rendering route') setIsLoading(false);
    };

    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeCompleteHandler);

    if (process.env.NODE_ENV !== 'production') {
      router.events.on('routeChangeError', routeChangeError);
    }

    return () => {
      router.events.off('routeChangeStart', routeChangeStartHandler);
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);

      if (process.env.NODE_ENV !== 'production') {
        router.events.off('routeChangeError', routeChangeError);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading;
};

const mobileNavBlackList = [
  '/home-appraisal/property-details/results', '/search', '/explore-exp', '/referrals', '/my-link-my-lead',
  '/sell', '/buy', '/agents-search', '/join-exp', '/exp-specialized-divisions', '/experts', '/income', '/relocation', '/reo'];

const defaultRenderHeader = (themeName: ThemeNames, routeName?: RouteName) => {
  const useZooHeader = themeName === ThemeNames.ZOOCASA;
  const useCommercialHeader = themeName === ThemeNames.EXP_COMMERCIAL;
  const useExpHeader = isThemeExpCA(themeName) || isThemeExpUS(themeName);
  const useExpInServiceHeader = themeName === ThemeNames.EXP_IN_SERVICE;
  return (
    <>
      {useExpInServiceHeader && <NewExpHeader config={expInServiceTenant.config.header} />}
      {useZooHeader && <ZooHeader routeName={routeName} />}
      {useExpHeader && <ExpHeader />}
      {useCommercialHeader && <CommercialHeader />}
    </>
  );
};

export default function Layout({
  children,
  className = '',
  showChatWidget,
  showMobileBottomNav,
  routeName,
  renderHeader,
}: LayoutProps) {
  const isLoading = useRouterLoading();
  const router = useRouter();
  const { themeName } = useThemeContext();
  const [ChatWidget, setChatWidget] = useState<any>();
  const pageName = router.asPath.replace(/\//g, '-').substring(1);
  const { areDelayedScriptsLoaded } = useFeaturesContext();

  let isChatWidgetEnabled: boolean;
  if (showChatWidget === undefined) {
    const { features, isWebView, isMobile } = useFeaturesContext();
    const isBlogWidget = pageName.startsWith('team-');
    isChatWidgetEnabled = features.useSmartChat && !isMobile && !isBlogWidget && !isWebView;
  } else {
    isChatWidgetEnabled = showChatWidget;
  }

  let isMobileBottomNavEnabled: boolean;
  if (showMobileBottomNav === undefined) {
    isMobileBottomNavEnabled = !mobileNavBlackList.includes(router.route);
  } else {
    isMobileBottomNavEnabled = showMobileBottomNav;
  }

  useEffect(() => {
    if (isChatWidgetEnabled && !ChatWidget) {
      setChatWidget(dynamic(import('components/chat-widget')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatWidgetEnabled]);

  useEffect(() => {
    if (areDelayedScriptsLoaded) {
      trackDelayedPageView();
    }

  }, [areDelayedScriptsLoaded]);

  useEffect(() => {
    // FIXME: This should keep track of the previous page visited and only track the
    // current page if it's a new page. this can cause multiple page views on the same page.
    trackCurrentPageView();
  }, []);

  return (
    <>
      {renderHeader ? renderHeader() : defaultRenderHeader(themeName, routeName)}
      {isLoading && (
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
        <div className={buildClassName(styles.page, className)}>
          {children}
        </div>
      )}
      {isChatWidgetEnabled && ChatWidget && (
        <ChatWidget />
      )}
      <div className={styles['mobile-nav']}>
        {isMobileBottomNavEnabled && <MobileBottomNav />}
      </div>
    </>
  );
}
